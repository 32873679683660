<template>
  <div class="document-request-from">
    <Header :typeComponent="'form'"/>
    <div class="text-center pa-5 mt-10">
      <div class="text-medium">現在サーバのメンテナンス中です。時間をおいて再度お試しください。</div>
      <div>{{ getMaintenanceMessage() }}</div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import { getMaintenanceMessage } from '@/utils/app'

export default {
  components: { Header },
  methods: {
    getMaintenanceMessage
  }
}
</script>
